// Generated by Framer (77e91d3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bk0JDFUCV"];

const serializationHash = "framer-5ZS6a"

const variantClassNames = {bk0JDFUCV: "framer-v-2sv9cz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image1, width, ...props}) => { return {...props, w7sJhkGoh: image1 ?? props.w7sJhkGoh} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image1?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, w7sJhkGoh, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bk0JDFUCV", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5ZS6a", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-2sv9cz", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bk0JDFUCV"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-b0ed99f9-6fa0-4e1a-bf69-922b8104b9bb, rgb(87, 79, 56))", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}}><Image __perspectiveFX={false} __targetOpacity={1} background={{alt: "", fit: "fill", sizes: "min(min(1312px, 100vw), 1920px)", ...toResponsiveImage(w7sJhkGoh)}} className={"framer-1x0f0jj"} data-framer-name={"img 1"} layoutDependency={layoutDependency} layoutId={"hY9NjPkkl"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5ZS6a [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5ZS6a .framer-1574jz4 { display: block; }", ".framer-5ZS6a .framer-2sv9cz { height: 1085px; max-width: 1920px; overflow: hidden; position: relative; width: 1312px; will-change: var(--framer-will-change-override, transform); }", ".framer-5ZS6a .framer-1x0f0jj { flex: none; height: 1085px; left: 0px; max-width: 1920px; overflow: hidden; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1085
 * @framerIntrinsicWidth 1312
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"1920px",null,null]}}}
 * @framerVariables {"w7sJhkGoh":"image1"}
 * @framerImmutableVariables true
 */
const FramerFpMZ4NtMQ: React.ComponentType<Props> = withCSS(Component, css, "framer-5ZS6a") as typeof Component;
export default FramerFpMZ4NtMQ;

FramerFpMZ4NtMQ.displayName = "img 1";

FramerFpMZ4NtMQ.defaultProps = {height: 1085, width: 1312};

addPropertyControls(FramerFpMZ4NtMQ, {w7sJhkGoh: {title: "Image 1", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerFpMZ4NtMQ, [])